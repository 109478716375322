:root {
  --font-gilroy: "Gilroy", sans-serif;
}

body {
  font-family: var(--font-gilroy);
}

a {
  color: #000000;
}

::-webkit-scrollbar {
  width: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  list-style: none;
  scroll-behavior: smooth;
}

.container {
  max-width: 1128px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.bg_active {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
}

.main_modal {
  padding: 60px 30px 30px 30px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 12;
  margin: auto;
  max-width: 480px;
  width: 100%;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 3px 16px rgba(29, 57, 169, 0.12);
  border-radius: 30px;

  .error-message {
    color: #e31e24;
    margin-bottom: 10px;
    max-width: 380px;
  }

  // loading styles
  .login_loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.65);

    .lds-ring {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 80px;
      height: 80px;

      div {
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 3px solid #e0e0e0;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #000000 transparent transparent transparent;

        &:nth-child(1) {
          animation-delay: -0.45s;
        }

        &:nth-child(2) {
          animation-delay: -0.3s;
        }

        &:nth-child(3) {
          animation-delay: -0.15s;
        }
      }
    }

    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  h1 {
    color: #141316;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
    margin-bottom: 10px;
  }

  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .main_modal_info {
    display: block;
    color: #75758b;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    margin-bottom: 30px;
  }

  label {
    display: block;
    color: #141316;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0.3px;
    margin-bottom: 8px;
  }

  .main_input {
    width: 100%;
    height: 50px;
    border-radius: 12px;
    border: 1px solid #f0f1f2;
    background: #fff;
    padding: 0 16px;
    margin-bottom: 20px;

    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;

    ::placeholder {
      color: #75758b;
    }

    &:last-child {
      margin: 0;
    }
  }

  .react-tel-input {
    input {
      width: 100%;
      height: 50px;
      border-radius: 12px;
      border: 1px solid #f0f1f2;
      background: #fff;
      padding: 0 16px;
      margin-bottom: 20px;

      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 135%;

      ::placeholder {
        color: #75758b;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .phone_error input {
    border: 1px solid red;
  }

  .main_modal_btn {
    transition: all ease 0.5s;
    width: 100%;
    height: 50px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 30px;
    margin-top: 20px;
    border-radius: 8px;
    background: #000000;
    border: 1px solid #000000;

    &:hover {
      cursor: pointer;
      transition: all ease 0.5s;
      background: transparent;
      color: #000000;
    }
  }

  .modal_main_offer {
    color: #334150;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;

    b {
      color: #007ee5;
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
}

.sms_code {
  input {
    width: 100%;
    height: 60px;
    opacity: 0;
  }

  .vi__container {
    width: 100%;
    gap: 12px;
    height: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    .vi__character {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      border: 1px solid #f0f1f2;
      background: #fff;

      color: #121512;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
    }

    .vi__character--selected {
      outline: 2px solid #000000;
    }
  }

  .sms_password_timer,
  .again {
    display: block;
    color: #aaacaa;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: 0.2px;
  }

  .again {
    display: block;
    color: #000000;
    cursor: pointer;
  }

  .again_none {
    display: none;
  }
}

header {
  position: relative;
  transition: all ease 0.5s;
  z-index: 2;
  border-bottom: 1px solid #f0f1f2;

  // header top styles
  .header_top {
    background: #000000;
    padding: 15px 0;

    .header_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header_left {
        display: flex;
        align-items: center;
        column-gap: 16px;

        .header_logo {
          display: block;
          padding-right: 16px;
          border-right: 1px solid #fff;
        }

        .header_list {
          display: flex;
          align-items: center;
          gap: 32px;

          .header_list_item {
            a {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }

      .header_right {
        display: flex;
        align-items: center;
        column-gap: 16px;

        .language {
          position: relative;

          .language_block {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            width: 90px;
            height: 38px;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.16);
            background: rgba(255, 255, 255, 0.12);
            text-align: center;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 38px;
            cursor: pointer;
            transition: all ease 0.5s;

            &:hover {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          }

          .language_select {
            width: 90px;
            transition: all ease 0.2s;
            visibility: hidden;
            z-index: 2;
            right: 0;
            margin: auto;
            position: absolute;
            top: 100%;
            background: #ffffff;
            box-shadow: 0px 3px 15px rgba(29, 57, 169, 0.17);
            border-radius: 0px 0px 5px 5px;

            .language_select_block {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              border-bottom: 1px solid #eef0f5;
              color: #4c4c4c;
              padding: 9px 5px;
              text-align: center;

              &::before {
                content: "";
                position: absolute;
                width: 16px;
                height: 12px;
                left: 9px;
                top: 0;
                bottom: 0;
                margin: auto;
                background-size: 100%;
                background-repeat: no-repeat;
              }

              &:hover {
                transition: all ease 0.5s;
                color: #000000;
              }
            }

            .none {
              display: none;
            }
          }

          &:hover {
            .language_select {
              visibility: visible;
            }
          }
        }

        .header_btn {
          height: 38px;
          border-radius: 8px;
          background: #fff;
          border: 1px solid #fff;
          width: 88px;

          color: #21201f;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          transition: 0.3s ease-in;

          &:hover {
            transition: 0.3s ease-in;
            background: transparent;
            cursor: pointer;
            color: #fff;
          }
        }
      }
    }
  }

  // header bottom styles
  .header_bottom {
    padding: 12px 0;
    background: #fff;

    .header_bottom_wrapper {
      display: flex;
      align-items: center;
      column-gap: 12px;

      .header_catalog_btn {
        display: flex;
        align-items: center;
        column-gap: 8px;

        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 8px;
        background: #000000;
        border: 1px solid #000000;
        height: 38px;
        padding: 0 12px;
        transition: all 0.5s ease;

        &:hover {
          cursor: pointer;
          transition: all 0.5s ease;
          color: #000000;
          background: transparent;

          svg {
            path {
              fill: #000000;
            }
          }
        }
      }

      .header_catalog_close {
        color: #ffffff !important;
      }

      .header_bottom_list {
        display: flex;
        align-items: center;
        column-gap: 12px;

        li {
          position: relative;
          width: 38px;
          height: 38px;
          border-radius: 8px;
          background: #ebf9f6;
          border: 1px solid #ebf9f6;
          transition: all ease 0.5s;

          &:hover {
            cursor: pointer;
            transition: all ease 0.5s;
            border-color: #000000;
            background: transparent;
          }

          a {
            &::after {
              content: "";
              position: absolute;
              background: url(./assets/svg/cart.svg);
              background-repeat: no-repeat;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              width: 20px;
              height: 20px;
            }
          }
        }

        .like {
          a {
            &::after {
              background: url(./assets/svg/like.svg);
            }
          }
        }
      }
    }
  }

  .catalog {
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    transition: all ease 0.8s;
    opacity: -1;
    padding-top: 0;
    display: flex;
    justify-content: center;

    .bg_white {
      position: absolute;
      z-index: 100;
      background: #fff;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px solid #e6e5ed;
      border-top: none;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0;
      overflow-x: hidden;
      height: 450px;

      .catalog_left {
        position: relative;
        max-width: 280px;
        width: 100%;
        padding: 20px;

        .catalog_left_block {
          margin-bottom: 4px;

          &:last-child {
            margin: 0;
          }

          &:hover {
            .catalog_right {
              visibility: visible;
              z-index: 20;
            }

            .main_link {
              background: #f2f4f6;

              &::before {
                background-image: url(./assets/svg/catalogArrow.svg);
                width: 16px;
                height: 16px;
              }

              span {
                transition: all ease 0.5s;
              }
            }
          }

          .main_link {
            position: relative;
            display: flex;
            align-items: center;
            height: 40px;
            border-radius: 8px;
            gap: 8px;
            padding: 8px;

            &::before {
              content: "";
              width: 16px;
              height: 16px;
              background-image: url(./assets/svg/catalogArrow.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100%;
              top: 0;
              bottom: 0;
              margin: auto;
              position: absolute;
              right: 14px;
            }

            svg {
              width: 31px;
              height: 31px;
              object-fit: contain;
            }

            span {
              color: #141316;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              /* identical to box height */
              color: #000000;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              min-height: 18px;
              width: 100%;
            }
          }
        }

        .catalog_right {
          visibility: hidden;
          display: flex;
          justify-content: space-between;
          transition: ease-in-out 0.1s;
          gap: 30px;
          z-index: 10;
          padding: 30px 45px;
          top: 0;
          position: absolute;
          left: 100%;
          width: 1158px;
          height: auto;
          background: #fff;
          overflow-y: auto;
          border-left: 1px solid #eeebeb;
          border-radius: 5px;

          &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background: #b1b0b0;
          }

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: #ececec;
            border-radius: 20px;
          }

          .catalog_right_menu {
            width: 750px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 60px;
            flex-wrap: wrap;
            height: auto;

            .catalog_right_block {
              padding-bottom: 20px;
              height: auto;

              .catalog_right_children {
                display: flex;
                align-items: center;
                // justify-content: center;
                // background-color: red;
              }

              h5 {
                color: #141316;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 135%;
                margin-bottom: 16px;
              }

              ul {
                li {
                  display: none;

                  a {
                    // transition: all ease 0.5s;
                    color: #334150;
                    font-size: 14px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 21px;
                    display: table;
                    padding-bottom: 2px;

                    &:hover {
                      // transition: all ease 0.5s;
                      color: #000000;
                    }
                  }
                }

                li:nth-child(-n + 5) {
                  display: block;
                }
              }

              .limit_active {
                li {
                  display: block;
                }

                .more {
                  display: none;
                }
              }

              .more {
                // transition: all ease 0.5s;
                background: #eff0fa;
                border-radius: 5px;
                height: 24px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                padding: 0 7px;
                display: table;
                margin-top: 20px;
                color: #5e5e5e;
                cursor: pointer;

                &:hover {
                  // transition: all ease 0.5s;
                  box-shadow: 0px 6px 14px 0px rgba(85, 85, 88, 0.1);
                }
              }
            }
          }
        }
      }
    }
  }

  .catalog_active {
    height: auto;
    opacity: 1;
    padding-bottom: 30px;
    height: 100vh;
    // z-index: 50;
  }
}

.main_bg_active {
  position: fixed;
  top: 142px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background: rgba(0, 0, 0, 0.25);
}

// active header styles
.header_active {
  transition: all ease 0.5s;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  box-shadow: 0px 8px 17px rgba(29, 57, 169, 0.1);
}

.search {
  width: 100%;
  position: relative;
  padding: 4px;

  .wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
  }

  .routes {
    background-color: #000000;
    color: white;
    position: absolute;
    // right: -790px;
    top: 30px;
    // transform: translateY(10px);
    transform: translate(785px, -15px);
    width: 80px;
    height: 32px;
    border-radius: 6px;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: #33b294;
    }
  }

  .wrapper_response {
    position: absolute;
    top: 52px;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #e6e5ed;
    border-radius: 8px;
    padding: 12px;
  }

  input {
    border-radius: 8px;
    width: 100%;
    padding: 12px;
    border: 1px solid #e6e5ed;
    padding-left: 48px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    transition: all 0.3s ease;

    &::placeholder {
      color: #b3b1c0;
    }

    &:focus + button + .old_search {
      display: block;
    }
    &:focus + button + .search_results {
      display: block;
    }

    &:active {
      transition: all ease 0.5s;
      border: 1px solid #000000;
    }

    &:focus {
      transition: all ease 0.5s;
      border: 1px solid #000000;
    }

    &:hover {
      transition: all ease 0.5s;
      border: 1px solid #000000;
    }
  }

  input:focus + button {
    svg {
      color: #000000;
    }
  }

  input:hover + button {
    svg {
      color: #000000;
    }
  }

  &:focus-within {
    .routes {
      display: flex;
    }
  }

  button {
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    color: #b3b1c0;

    svg {
      transition: color 0.3s ease;
    }

    &:hover svg {
      color: #000000;
    }
  }

  .result_item {
    color: #000000;
    background-color: s;
  }

  .result__box {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }

  .search__heading {
    font-size: 14px;
  }
}

// product card style
.product_card {
  position: relative;
  border-radius: 20px;
  padding-bottom: 16px;
  border: 1px solid #e5e7e9;
  // width: 270px;

  transition: all ease 0.5s;

  &:hover {
    transition: all ease 0.5s;
    border-color: #000000;

    .product_card_main {
      .product_card_text_wrap {
        .product_card_prices {
          transition: all ease 0.5s;
          border-bottom-color: #000000;
        }
      }
    }
  }

  .product_card_main {
    .product_card_img_block {
      padding: 0 32px;
      margin-bottom: 16px;

      img {
        width: 100%;
        object-fit: contain;
        max-height: 200px;
      }
    }

    .product_card_text_wrap {
      padding: 0 10px;
      margin-bottom: 16px;

      .headline {
        color: #334150;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 134.8%;
        height: 40px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        width: 100%;
        margin-bottom: 12px;
      }

      .product_card_prices {
        display: flex;
        align-items: center;
        column-gap: 8px;
        font-size: 14px;

        span {
          display: block;
          color: #75758b;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration: line-through;

          &:last-child {
            color: #000000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration: none;
          }
        }

        transition: all ease 0.5s;
        padding-bottom: 16px;
        border-bottom: 1px solid #e5e7e9;
      }
    }
  }

  .like_item {
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;
    transition: all ease 0.5s;

    path {
      stroke: #75758b;
    }

    &:hover {
      transition: all ease 0.5s;
      path {
        stroke: #000000;
        fill: #000000;
      }
    }
  }

  .active_like {
    path {
      stroke: #000000;
      fill: #000000;
    }
  }

  .rating {
    position: absolute;
    top: 180px;
    left: 10px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    border-radius: 99px;
    background: #ffc100;
    height: 32px;
    width: 70px;

    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    padding: 0 12px;
  }

  .product_card_bottom {
    padding: 0 10px;

    .product_card_bottom_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;

      height: 42px;
      width: 100%;
      border-radius: 8px;
      background: #000000;
      border: 1px solid #000000;
      cursor: pointer;

      svg {
        transition: all ease 0.5s;
      }

      &:hover {
        svg {
          transition: all ease 0.5s;

          transition: all ease 0.5s;
          transform: scale(1.2);
        }
      }
    }

    .product_card_bottom_add {
      display: flex;
      align-items: center;
      column-gap: 12px;

      button {
        width: 48px;
        height: 42px;
        border-radius: 8px;
        border: 1px solid rgba(229, 231, 233, 0.5);
        background: #fff;

        &:last-child {
          background: #000000;
          border-color: #000000;
        }
      }

      .product_card_bottom_add_result {
        display: block;
        width: 50%;
        height: 42px;
        border-radius: 8px;
        border: 1px solid #e5e7e9;
        background: #fff;

        color: #334150;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
        text-align: center;
      }
    }
  }
}

// category card style
.category_card {
  position: relative;
  padding: 50px 20px;
  border-radius: 30px;
  height: 370px;
  overflow: hidden;
  transition: all ease 0.5s;

  .category_card_img_block {
    img {
      object-fit: contain;
      width: 60px;
      height: 60px;
    }
  }

  &:hover {
    transition: all ease 0.5s;

    .category_card_img_block {
      img {
        transition: all ease 0.5s;
        transform: scale(1.1);
      }
    }
  }

  .category_card_title {
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 10px;
  }

  .category_card_info {
    display: block;
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    text-align: center;
  }

  .category_card_img_block {
    position: absolute;
    display: block;
    width: 340px;
    height: 319px;
    bottom: -18%;
    transition: all ease 0.5s;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      transition: all ease 0.5s;
    }
  }

  .category_card_touch {
    position: absolute;
    right: 30px;
    bottom: 20px;
  }
}

// similar product styles
.similar_products {
  position: relative;
  padding-bottom: 60px;

  .similar_products_title {
    color: #141316;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
    margin-bottom: 20px;
  }

  .similar_products_slider {
    .slick-list {
      .slick-track {
        height: 390px;
        overflow: hidden;
      }
    }

    .slick-slide {
      margin-left: 24px;
      max-width: 260px !important;
      width: 100%;

      &:first-child {
        margin-left: 0;
      }
    }

    // arrow style
    .slick-arrow {
      position: absolute;
      display: block;
      width: 42px;
      height: 42px;
      border-radius: 8px;
      background: #ebf9f6;
      top: -40px;
      right: 0;

      &::before {
        content: "";
        display: block;
        background: url(./assets/svg/arrowBrand.svg);
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        margin: auto;
      }
    }

    .slick-prev {
      left: calc(100% - 95px);
      &::before {
        transform: rotate(180deg);
      }
    }
  }
}

// footer styles
footer {
  background: #04211a;
  padding-top: 60px;
  padding-bottom: 20px;

  .footer_top {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 60px;
    padding-bottom: 32px;
    border-bottom: 1px solid #29413e;
    margin-bottom: 30px;

    .footer_top_wrap {
      .footer_top_headline {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        margin-bottom: 12px;
      }

      .footer_top_list {
        li {
          margin-bottom: 12px;
          transition: all ease 0.5s;

          a {
            color: rgba(255, 255, 255, 0.5);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }

          &:hover {
            cursor: pointer;
            transition: all ease 0.5s;

            a {
              color: #fff;
            }
          }
        }
      }

      .footer_top_wrap_socials {
        display: flex;
        align-items: center;
        column-gap: 12px;

        span {
          position: relative;
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #28403d;
          cursor: pointer;
          transition: all ease 0.5s;

          svg {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            opacity: 0.6;
          }

          &:hover {
            transition: all ease 0.5s;

            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .footer_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      opacity: 0.7;
    }
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
  border: 1px dashed #c7c7c7 !important;
  position: relative;
}

.disabled:after {
  content: "";
  width: 1px;
  height: calc(99% + 8px);
  transform: rotate(47deg);
  position: absolute;
  right: 14px;
  top: -5px;
  border: 1px dashed #c7c7c7 !important;
}

.disabled-store {
  width: 70px;
  height: 38px;
  border-radius: 8px 0px 0px 0px;
  border: 1px dashed #c7c7c7 !important;
  border-top-width: 1px;
  opacity: 0.5;
  cursor: not-allowed !important;
  position: relative;
}

.disabled-store:after {
  content: "";
  width: 1px;
  height: calc(101% + 32px);
  transform: rotate(63deg);
  position: absolute;
  right: 32px;
  top: -17px;
  border: 1px dashed #c7c7c7 !important;
}

.active-store {
  background-color: #000000 !important;
  color: #ffffff !important;
  border: none !important;
}

.active-color {
  position: relative;
  width: 100px;
  height: 100px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 31px;
    height: 31px;
    background-image: url("../[locale]/assets/svg/check.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.not_found_page {
  width: 100%;
  display: flex;
  justify-content: center;

  .not_found_page_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .not_found_page_headline {
    margin-top: 50px;
    margin-bottom: 12px;
    font-size: 54px;
    line-height: 68px;
  }

  .not_found_page_info {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 32px;
  }

  .not_found_page_image {
    margin-bottom: 32px;
  }

  .not_found_page_btn {
    font-size: 14px;
    line-height: 19px;
    padding: 12px 20px;
    border: 1px solid #e6e5ed;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 50px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 1118px;
}

.modal-close-btn {
  cursor: pointer;
  position: absolute;
  top: 35px;
  background-color: #ffffff;
  right: 25px;
  z-index: 100;
  padding: 13px;
  border-radius: 14px;
  border: 1px solid #e9e8ee;
}

.home-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000040;

  .home-modal {
    position: absolute;
    width: 560px !important;
    padding: 20px;
    z-index: 30;
    height: 600px;
  }

  .home-close {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
  }
}
