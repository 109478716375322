.catalog__top {
  h1 {
    margin-top: 30px;
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    color: #75758b;
    line-height: 21px;
    font-weight: 500;
  }
}

.catalog {
  display: flex;
  align-items: flex-start;
  column-gap: 24px;
  padding: 16px 0;

  .catalog_header {
    margin-bottom: 20px;

    .catalog_header_wrap {
      display: flex;
      align-items: center;
      column-gap: 12px;

      .main_filter_input {
        display: none;
      }

      .main_filter_input + label {
        display: block;
        color: #21201f;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        height: 42px;
        border-radius: 8px;
        border: 1px solid #e5e7e9;
        background: #fff;
        padding: 10px 20px;
        text-align: center;

        cursor: pointer;
      }

      .main_filter_input:checked + label {
        color: #fff;
        background: #000000;
        border-color: #000000;
      }
    }
  }

  .catalog__main {
  }

  .catalog-filter {
    min-width: 264px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #e5e7e9;
    box-shadow: 0px 4px 30px 0px #3b82f60f;
    border-radius: 12px;
    padding: 16px;

    h3 {
      color: #141316;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.6;
    }

    .accordion_body {
      transition: max-height ease 0.5s;
      overflow: hidden;
      max-height: 0px;
      input {
        display: none;
      }

      input:checked + label::before {
        background: #000000;
        background-image: url(../../assets/svg/checked.svg);
        background-size: 80%;
        background-position: 50%;
        background-repeat: no-repeat;
        border: 1px solid #000000;
      }

      label {
        display: block;
        margin-bottom: 10px;

        &:last-child {
          margin: 0;
        }

        position: relative;
        transition: all ease 0.5s;
        cursor: pointer;
        color: #75758b;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 135%;
        letter-spacing: 0.07px;
        padding-left: 30px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: -2px;
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid #000000;
          cursor: pointer;
        }

        &:hover {
          transition: all ease 0.5s;
          color: #000000;
        }
      }
    }

    .accordion {
      border: none !important;
      border-radius: 8px;
      padding: 16px;
      max-width: 300px;
      cursor: pointer;
      user-select: none;

      &.open {
        .accordion-header svg {
          transform: rotate(180deg);
        }
      }

      .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #000;

        svg {
          transition: transform 0.3s ease;
        }
      }

      .accordion-body {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .checkbox {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          color: #6e6e6e;
        }

        .checkbox input[type="checkbox"] {
          width: 16px;
          height: 16px;
          border: 1px solid #cfcfcf;
          border-radius: 4px;
          appearance: none;
          outline: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          transition: all 0.1s ease;
        }

        .checkbox input[type="checkbox"]:checked {
          background-color: #000000;
          border-color: #000000;
          background-image: url("../../assets/svg/check.svg"); /* Tasvir manzili */
          background-size: 18px 18px; /* Tasvir hajmi */
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .range-filter {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      // border: 1px solid #e0e0e0;
      border-radius: 8px;
      max-width: 300px;
      background-color: #fff;

      h3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .range-slider {
        position: relative;
        height: 32px;
        margin-top: 10px;

        .value-label {
          position: absolute;
          top: -35px;
          background-color: #000000;
          color: #fff;
          font-size: 12px;
          padding: 4px 8px;
          border-radius: 4px;
          transform: translateX(-50%);
          white-space: nowrap;
        }

        .value-label::before {
          content: "";
          width: 10px;
          height: 10px;
          background-color: #000000;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          z-index: -1;
        }

        .track {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          height: 8px;
          width: 100%;
          background-color: #e0e0e0;
          border-radius: 4px;

          .filled {
            height: 8px;
            background-color: #000000;
            position: absolute;
            top: 0;
            border-radius: 4px;
          }
        }

        input[type="range"] {
          -webkit-appearance: none;
          position: absolute;
          width: 100%;
          height: 8px;
          background: transparent;
          outline: none;
          pointer-events: none;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 12px;
            height: 12px;
            background: #000000;
            border-radius: 50%;
            border: 1px solid #e5e7e9;
            pointer-events: all;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }

          &::-moz-range-thumb {
            width: 20px;
            height: 20px;
            background: #000000;
            border-radius: 50%;
            pointer-events: all;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }
        }
      }

      .range-values {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #6e6e6e;
        font-weight: 600;
      }
    }

    .size-filter {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 8px;
      background-color: #fff;

      h3 {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
      }

      .sizes {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        .radio {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 13px;
          min-width: 52px;
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          background-color: #fff;
          font-size: 12px;
          font-weight: 500;
          color: #333;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            border-color: #000000;
            color: #000000;
          }

          .radio-input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
          }

          .radio-label {
            pointer-events: none;
          }

          .radio-input:checked + .radio-label {
            color: #fff;
            background-color: #000000;
            border-color: #000000;
          }
        }
      }
    }

    .accordion {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      padding: 16px;
      max-width: 300px;
      background-color: #fff;

      .accordion-header {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          color: #000000;
        }
      }

      .hovered-color {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 12px;
        color: #333;
      }

      .accordion-colorbd {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 12px;

        .color {
          position: relative;
          .radio-input {
            display: none;
          }

          .color-info {
            position: absolute;
            top: 10px;
          }

          .color-box {
            width: 32px;
            height: 32px;
            transition: all 0.3s ease;
            border-radius: 6px;
            border: 1px solid #f4f4f4;
            cursor: pointer;
            &:hover {
              border-color: #000000;
            }
          }
        }
      }
    }
  }

  .main_catalog_right {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    row-gap: 30px;
  }
}
