.order {
  padding-bottom: 60px;

  h1 {
    color: #141316;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
  }

  .order_created_order {
    color: #75758b;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.3px;
    display: block;
    margin-bottom: 20px;
  }

  .order_main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 24px;

    .order_main_left {
      width: 70%;
      .order_main_wrap {
        padding: 20px;
        border-radius: 20px;
        border: 1px solid #e6e5ed;
        background: #fff;
        margin-bottom: 20px;

        &:last-child {
          margin: 0;
        }

        .order_title {
          color: #141316;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 135%;
          padding-bottom: 16px;
          margin-bottom: 16px;
          border-bottom: 1px solid #e5e7e9;
        }
      }

      .order_main_delivery {
        .delivery_wrap {
          display: flex;
          align-items: center;
          border-radius: 16px;
          background: #f4f6f6;
          padding: 4px;
          margin-bottom: 16px;

          input {
            display: none;
          }

          input:checked + label {
            background: #000000;
            background-size: 100%;
            border-radius: 12px;
            background-repeat: no-repeat;
            border: 1px solid #000000;
            color: #fff;
          }

          label {
            display: block;
            width: 50%;
            height: 42px;
            cursor: pointer;

            color: #0f1722;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0.07px;
            border: 1px solid transparent;
            text-align: center;
          }
        }

        .delivery_label {
          display: block;
          color: #141316;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 130%;
          letter-spacing: 0.3px;
          margin-bottom: 8px;
        }

        .delivery_input {
          height: 50px;
          width: 100%;
          border-radius: 12px;
          border: 1px solid #f0f1f2;
          background: #fff;
          padding: 0 16px;
          margin-bottom: 16px;

          color: #000;
          ::placeholder {
            color: #75758b;
          }
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 135%;
        }

        .delivery_info {
          display: block;
          color: #334150;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          letter-spacing: 0.3px;
          margin-bottom: 16px;
        }

        .delivery_btn {
          height: 42px;
          width: 100%;
          border-radius: 9px;
          border: 1px solid #e6e5ed;
          background: #fff;

          color: #21201f;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 135%;
          cursor: pointer;
        }
      }

      .ordered_goods {
        .ordered_goods_card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 16px;
          border-bottom: 1px solid #e5e7e9;
          margin-bottom: 16px;

          &:last-child {
            margin: 0;
          }

          .ordered_goods_card_left {
            display: flex;
            align-items: center;
            column-gap: 12px;

            img {
              border-radius: 8px;
              object-fit: contain;
            }

            .ordered_goods_card_left_texts {
              h3 {
                color: #334150;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 4px;
              }

              span {
                display: block;
                color: #75758b;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                letter-spacing: 0.3px;
                margin-bottom: 8px;

                &:last-child {
                  margin: 0;
                  color: #334150;
                }
              }
            }
          }

          .ordered_goods_card_right {
            .ordered_goods_card_right_price {
              display: block;
              color: #334150;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 130%;
              letter-spacing: 0.3px;
              margin-bottom: 8px;
            }

            .ordered_goods_card_right_delete {
              display: block;
              margin-left: auto;
              position: relative;
              width: 32px;
              height: 32px;
              border-radius: 8px;
              background: #fbe1e2;
              border: 1px solid #fbe1e2;
              transition: all ease 0.5s;
              cursor: pointer;

              &:hover {
                background: transparent;
                transition: all ease 0.5s;

                svg {
                  transform: scale(1.1);
                }
              }

              svg {
                transition: all ease 0.5s;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
              }
            }
          }
        }
      }

      .recipient_order {
        .recipient_order_wrap {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          margin-bottom: 16px;

          label {
            display: block;
            color: #141316;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            letter-spacing: 0.3px;
          }

          input {
            display: block;
            height: 50px;
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            border-radius: 12px;
            border: 1px solid #f0f1f2;
            background: #fff;
            margin-top: 8px;

            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;
            ::placeholder {
              color: #75758b;
            }
          }
        }

        .recipient_order_info {
          position: relative;
          display: block;
          color: #334150;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          letter-spacing: 0.3px;
          padding-left: 24px;

          &::before {
            position: absolute;
            content: "";
            background: url(../../assets/svg/info.svg);
            background-repeat: no-repeat;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .order_details {
      padding: 20px;
      width: 380px;
      border-radius: 16px;
      border: 1px solid #e6e5ed;
      background: #fff;

      h3 {
        color: #334150;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 20px;
      }

      .order_details_body {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e5e7e9;

        .order_details_text_box {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
          margin: 12px 0;

          .order_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .key {
            flex: 1;
            color: #75758b;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: 0.3px;
          }

          .value {
            flex: 1;
            color: #334150;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: end;
          }
        }

        .order_details_text_wrap {
          display: flex;
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }

          .key {
            flex: 1;
            color: #75758b;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: 0.3px;
          }

          .value {
            flex: 1;
            color: #334150;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: end;
          }
        }
      }

      .order_details_total_wrap {
        display: flex;
        margin-bottom: 20px;

        .key {
          flex: 1;
          color: #75758b;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
          letter-spacing: 0.3px;
        }

        .value {
          flex: 1;
          font-size: 20px;
          font-weight: 600;
          line-height: 24.5px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          text-align: end;
        }
      }

      .details_btn {
        height: 42px;
        width: 100%;
        border-radius: 8px;
        background: #000000;
        border: 1px solid #000000;

        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        transition: all ease 0.5s;
        cursor: pointer;

        &:hover {
          transition: all ease 0.5s;
          background: transparent;
          color: #000000;
        }
      }
    }
  }
}

.maps__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.map-container {
  display: flex;
  justify-content: space-between;
  width: 1118px;
  height: 600px;
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 30px;
}

.map-card {
  display: flex;
  justify-content: space-between;
  gap: 12px;

  .map-header {
    width: 360px;
    padding: 20px;
    border: 1px solid #e6e5ed;
    border-radius: 20px;

    h2 {
      font-size: 20px;
      line-height: 27px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    .map__box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 8px;
      color: #334150;
    }

    .map__btn {
      padding: 12px;
      border: none;
      background-color: #000000;
      color: white;
      border-radius: 8px;
      font-size: 14px;
      cursor: pointer;
    }

    .input-group {
      display: flex;
      flex-direction: column;
      gap: 5px;
      border-top: 1px solid #e6e5ed;
      padding-top: 20px;

      label {
        font-size: 14px;
        font-weight: 600;
        color: #141316;
        margin-bottom: 6px;
      }

      input {
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #000000;
        border-radius: 5px;
        outline-color: #000000;

        &:focus {
          outline: none;
          border-color: #000000;
        }
      }
    }
  }

  .map-body {
    width: 720px;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    border-radius: 20px;
  }

  .map-footer {
    h3 {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 0.9rem;
      margin: 5px 0;

      strong {
        font-weight: 600;
      }
    }

    .submit-button {
      padding: 10px 20px;
      background-color: #28a745;
      color: #fff;
      font-size: 1rem;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #218838;
      }
    }
  }
}
